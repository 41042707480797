// import { useEffect } from 'react';
import Routes from './routes';
import ThemeCustomization from './themes';
import AnimationHelper from './utils/animationHelper';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import { useLocation, useNavigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

const App = () => {
  // const location = useLocation();
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const hostname = window.location.hostname;
  //   if (hostname === 'www.hriplus.com') {
  //     navigate(`https://hriplus.com${location.pathname}`);
  //   }
  // }, [navigate, location.pathname]);

  return (
    <ThemeCustomization>
      <AnimationHelper />
      <Toaster position="top" />
      <Routes />
    </ThemeCustomization>
  );
};
export default App;
